import request from '@/api/http'

const user = {
    modify (params,callback) {
        return request.post(process.env.BASE_URL+`/user/modify`, params,callback)
    },
    bindUserAndWx (params,callback) {
        return request.post(process.env.BASE_URL+`/user/bindUserAndWx`, params,callback)
    },
}

export default user

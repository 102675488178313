import request from '@/api/http'

const weeklyReports = {
    addWeeklyReports (params,callback) {
        return request.post(process.env.BASE_URL+`/weeklyReports/addWeeklyReports`, params,callback)
    },
    queryWeeklyReports (params,callback) {
        return request.post(process.env.BASE_URL+`/weeklyReports/queryWeeklyReports`, params,callback)
    },

}

export default weeklyReports

import request from '@/api/http'

const employeeClock = {
    // post提交
    addEmployeeClock (params,callback) {
        return request.post(process.env.BASE_URL+`/employeeClock/addEmployeeClock`, params,callback)
    },
    queryEmployeeSingInInfo(params,callback){
        return request.post(process.env.BASE_URL+`/employeeClock/queryEmployeeSingInInfo`, params,callback)
    },
    queryEmployeeClock(params,callback){
        return request.post(process.env.BASE_URL+`/employeeClock/queryEmployeeClock`, params,callback)
    },
    queryEmployeeAttendance(params,callback){
        return request.post(process.env.BASE_URL+`/employeeAttendance/queryEmployeeAttendance`, params,callback)
    },
    addEmployeeSpecialClock(params,callback){
        return request.post(process.env.BASE_URL+`/employeeClock/addEmployeeSpecialClock`, params,callback)
    },
    employeeVacation(params,callback){
        return request.post(process.env.BASE_URL+`/employeeClock/employeeVacation`, params,callback)
    }
}

export default employeeClock

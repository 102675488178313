/***    设备    ***/
const device = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceHome")
const DeviceList = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceList")
const DeviceDetail = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceDetail")
const deviceRepair = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceRepair")
const deviceScrap = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceScrap")
const DeviceCommitHistory = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceCommitHistory")
const DeviceMaintenance = () => import(/* webpackChunkName: "device" */ "@/views/device/repair/DeviceMaintenance")
const DeviceRepairList = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceRepairList")
const DeviceRepairDetail = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceRepairDetail")
const DeviceRepairFeedback = () => import(/* webpackChunkName: "device" */ "@/views/device/DeviceRepairFeedback")

export default [
    {
        path: '/device',
        name: 'DeviceHome',
        meta: {
            index: 4,
            title: "审批"
        },
        component: device,
        children:[
            {
                path: 'deviceList',
                name: 'DeviceList',
                meta: {
                    index: 5,
                    title: "设备列表"
                },
                component: DeviceList
            },
            {
                path: 'repairList',
                name: 'DeviceRepairList',
                meta: {
                    index: 5,
                    title: "报修列表"
                },
                component: DeviceRepairList
            }
        ]
    },
    {
        path: '/device/deviceScrap',
        name: 'deviceScrap',
        meta: {
            index: 5,
            title: "报废申请"
        },
        component: deviceScrap
    },
    {
        path: '/device/deviceRepair',
        name: 'deviceRepair',
        meta: {
            index: 6,
            title: "设备报修"
        },
        component: deviceRepair
    },
    {
        path: '/device/deviceDetail/:type/:id?',
        name: 'DeviceDetail',
        meta: {
            index: 6,
            title: "设备详情"
        },
        component: DeviceDetail
    },
    {
        path: '/device/repairDetail/:type/:id?',
        name: 'DeviceRepairDetail',
        meta: {
            index: 6,
            title: "报修详情"
        },
        component: DeviceRepairDetail
    },
    {
        path: '/device/history',
        name: 'DeviceCommitHistory',
        meta: {
            index: 6,
            title: "设备提交历史"
        },
        component: DeviceCommitHistory
    },
    {
        path: '/device/repair/feedback/:id',
        name: 'DeviceRepairFeedback',
        meta: {
            index: 7,
            title: "设备维修反馈"
        },
        component: DeviceRepairFeedback
    },
    {
        path: '/device/maintenance/list',
        name: 'DeviceMaintenance',
        meta: {
            index: 3,
            title: "设备维修列表"
        },
        component: DeviceMaintenance
    },
]

import request from '@/api/http'

const menu = {
    queryRoleMenuList(params,callback){
        return request.post(process.env.BASE_URL+`/menu/queryRoleMenuList`, params,callback)
    },
    //获取某个角色下的菜单列表
    getUserMenu(params,callback){
        return request.post(process.env.BASE_URL+`/role/getUserMenu`, params,callback)
    },
}


export default menu

/***    demo    ***/
const TestHome = () => import(/* webpackChunkName: "test" */ "@/views/test/TestHome")

const AndroidApiTest = () => import(/* webpackChunkName: "test" */ "@/views/test/android/AndroidApiTest")
const AndroidAnalysis = () => import(/* webpackChunkName: "test" */ "@/views/test/android/AndroidAnalysis")

const NvwaTest = () => import(/* webpackChunkName: "test" */ "@/views/test/nvwa/NvwaTest")

export default [
    {
        path: '/test',
        name: 'TestHome',
        meta: {
            index: 100,
            title: "测试Demo"
        },
        component: TestHome,
    },
    {
        path: '/test/android',
        name: 'AndroidApiTest',
        meta: {
            index: 101,
            title: "安卓测试demo"
        },
        component: AndroidApiTest,
    },
    {
        path: '/android/analysis',
        name: 'AndroidAnalysis',
        meta: {
            index: 102,
            title: "友盟统计"
        },
        component: AndroidAnalysis,
    },
    {
        path: '/test/nvwa',
        name: 'NvwaTest',
        meta: {
            index: 102,
            title: "nvwa测试"
        },
        component: NvwaTest,
    },
]
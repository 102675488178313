import plugin from './plugins'
import request from './modules/request'
import navigation from './modules/navigation'
import openBrowser from './modules/openBrowser'
import push from './modules/push'
import analytics from './modules/analytics'

export default {
    plugin,
    request,
    ...navigation,
    openBrowser,
    push,
    analytics
}
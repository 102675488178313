var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "action_bar_body" }, [
    _c(
      "div",
      { ref: "actionBarSticky", staticClass: "action_bar_sticky" },
      [
        _vm.backgroundImg !== ""
          ? _c(
              "div",
              {
                staticClass: "action_bar_back_img",
                style: { height: _vm.topStickyHeight + "px" }
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.backgroundImg }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "van-nav-bar",
          {
            ref: "navBar",
            style: { backgroundColor: _vm.backgroundColor },
            attrs: { "z-index": "101", border: _vm.border },
            on: {
              "click-left": _vm.onClickLeft,
              "click-right": function($event) {
                return _vm.$emit("click-right")
              }
            }
          },
          [
            _vm.showBack
              ? _c(
                  "van-button",
                  {
                    attrs: { slot: "left", color: "transparent" },
                    slot: "left"
                  },
                  [
                    _c("van-icon", {
                      attrs: {
                        name: "arrow-left",
                        size: "20px",
                        color: _vm.color
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                style: { color: _vm.color },
                attrs: { slot: "title" },
                on: {
                  click: function($event) {
                    return _vm.titleClick()
                  }
                },
                slot: "title"
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "action_bar_default_right",
                attrs: { slot: "right" },
                slot: "right"
              },
              [_vm._t("right")],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._t("bottom")
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { style: { height: _vm.topStickyHeight + "px" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
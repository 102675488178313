export default {
    state: {
        "id": null,
        "employeeId": null,
        "employeeName": null,
        "employeeSex": null,
        "employeeAge": null,
        "birthday": null,
        "homeAddress": null,
        "currentAddress": null,
        "employeeMobile": null,
        "emergencyContactName": null,
        "emergencyContactMobile": null,
        "bankCardNumber": null,
        "bankCardPhoto": null,
        "entryTime": null,
        "leavingTime": null,
        "isActive": null,
        "photo": null,
        "identityNumber": null,
        "identityPhoto": null,
        "employeeOrgid": null,
        "position": null,
        "employeeType": null,
        "directionId": null,
        "directionName": null,
        "createTime": null,
        "updateTime": null,
        "orgName": null,
        "start": null,
        "pageSize": null,
        "entryTimeMin": null,
        "entryTimeMax": null,
        "leaveTimeMin": null,
        "leaveTimeMax": null,
        "bindProject": null,
        "paidSocialSecurity": null,
        "contractType": null,
        "positionType": null,
        "isRetired": null,
        "workStatus": null,
        "contractPhoto": null,
        "attendanceType": null,
        "projectName": null,
        "loginName": null,
        "openBankNews": null,
        "nation": "汉",
        "remark": null,
        "chargeProject": null,
        "userId": null,
        "orgList": null,
        "roleId": null,
        "createUserId": null,
        "projectDetail": null,
        "orgIdList": null,
        "department": 0
    },
    mutations: {
        updateEmployeeInfo(state, employeeInfo) {
            localStorage.setItem("employeeInfo", JSON.stringify(employeeInfo))
            Object.keys(employeeInfo).forEach(key => {
                state[key] = employeeInfo[key]
            })
        },
        updateDepartment(state, department) {
            localStorage.setItem("department", department)
            state.department = department
        },
        init(state) {
            let employeeInfo = JSON.parse(localStorage.getItem("employeeInfo"))
            if(employeeInfo==null){
                return
            }
            Object.keys(employeeInfo).forEach(key => {
                state[key] = employeeInfo[key]
            })
            state.department = localStorage.getItem("department")
        }
    },
}
/***    打卡    ***/
const Punch = () => import(/* webpackChunkName: "punch" */ "@/views/punch/Punch")
const OutSidePunch = () => import(/* webpackChunkName: "punch" */ "@/views/punch/OutSidePunch")
const PunchHistory = () => import(/* webpackChunkName: "punch" */ "@/views/punch/PunchHistory")
const MyAttendance = () => import(/* webpackChunkName: "punch" */ "@/views/punch/MyAttendance")
const Leave = () => import(/* webpackChunkName: "punch" */ "@/views/punch/Leave")
const EmployeeAttendanceDetail = () => import(/* webpackChunkName: "punch" */ "@/views/punch/EmployeeAttendanceDetail")

export default [
    {
        path: '/punch/new',
        name: 'Punch',
        meta: {
            index: 3,
            title: "考勤打卡"
        },
        component: Punch
    },
    {
        path: '/leave',
        name: 'Leave',
        meta: {
            index: 3,
            title: "请假"
        },
        component: Leave
    },
    {
        path: '/outside-punch/new',
        name: 'OutSidePunch',
        meta: {
            index: 3,
            title: "外勤打卡"
        },
        component: OutSidePunch
    },
    {
        path: '/punch/history',
        name: 'PunchHistory',
        meta: {
            index: 4,
            title: "打卡历史"
        },
        component: PunchHistory
    },
    {
        path: '/attendance/my',
        name: 'MyAttendance',
        meta: {
            index: 3,
            title: "我的考勤"
        },
        component: MyAttendance
    },
    {
        path: '/attendance/employee/:employeeId',
        name: 'EmployeeAttendanceDetail',
        meta: {
            index: 5,
            title: "员工考勤"
        },
        component: EmployeeAttendanceDetail
    },
]

/***                  问卷调查                    ***/

const QuestionnaireHome = () => import(/* webpackChunkName: "questionnaire" */ "@/views/questionnaire/QuestionnaireHome")
const QuestionnaireAnswer = () => import(/* webpackChunkName: "questionnaire" */ "@/views/questionnaire/QuestionnaireAnswer")
const SatisfactionHome = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/satisfaction/SatisfactionHome")
const SatisfactionDepartmentManager = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/satisfaction/module/SatisfactionDepartmentManager")
const SatisfactionAreaManager = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/satisfaction/module/SatisfactionAreaManager")
const SatisfactionDepartment = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/satisfaction/module/SatisfactionDepartment")
const SatisfactionCompany = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/satisfaction/module/SatisfactionCompany")
const SatisfactionHistory = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/satisfaction/SatisfactionHistory")
const GreeningQuestionnaire = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/partASatisfaction/GreeningQuestionnaire")
const CleaningQuestionnaire = () => import(/* webpackChunkName: "satisfaction" */ "@/views/questionnaire/module/partASatisfaction/CleaningQuestionnaire")
const CommonQuestion = () => import(/* webpackChunkName: "questionnaire" */ "@/views/questionnaire/module/CommonQuestion")

export default [
    {
        path: '/questionnaire/home',
        name: 'QuestionnaireHome',
        meta: {
            index: 3,
            title: "日报",
            needLogin: true
        },
        component: QuestionnaireHome,
    },
    {
        path: '/questionnaire/answer/:questionnaireId',
        name: 'QuestionnaireAnswer',
        meta: {
            index: 4,
            title: "日报",
            needLogin: true
        },
        component: QuestionnaireAnswer,
    },
    {
        path: '/questionnaire/satisfaction/home',
        name: 'SatisfactionHome',
        meta: {
            index: 4,
            title: "满意度调查"
        },
        component: SatisfactionHome
    },
    {
        path: '/questionnaire/satisfaction/history',
        name: 'SatisfactionHistory',
        meta: {
            index: 5,
            title: "提交历史"
        },
        component: SatisfactionHistory
    },
    {
        path: '/questionnaire/satisfaction/departmentManager/:type',
        name: 'SatisfactionDepartmentManager',
        meta: {
            index: 6,
            title: "职能部门负责人满意度调查"
        },
        component: SatisfactionDepartmentManager
    },
    {
        path: '/questionnaire/satisfaction/areaManager/:type',
        name: 'SatisfactionAreaManager',
        meta: {
            index: 6,
            title: "区域负责人满意度调查"
        },
        component: SatisfactionAreaManager
    },
    {
        path: '/questionnaire/satisfaction/department/:type',
        name: 'SatisfactionDepartment',
        meta: {
            index: 6,
            title: "职能部门满意度调查"
        },
        component: SatisfactionDepartment
    },
    {
        path: '/questionnaire/satisfaction/company/:type',
        name: 'SatisfactionCompany',
        meta: {
            index: 6,
            title: "公司满意度调查"
        },
        component: SatisfactionCompany
    },
    {
        path: '/questionnaire/part-a-satisfaction/greening',
        name: 'GreeningQuestionnaire',
        meta: {
            index: 6,
            title: "绿化服务满意度调查问卷"
        },
        component: GreeningQuestionnaire
    },
    {
        path: '/questionnaire/part-a-satisfaction/cleaning',
        name: 'CleaningQuestionnaire',
        meta: {
            index: 6,
            title: "保洁服务满意度调查问卷"
        },
        component: CleaningQuestionnaire
    },
    {
        path: '/questionnaire/common/:id',
        name: 'CommonQuestion',
        meta: {
            index: 6,
            title: "问卷调查"
        },
        component: CommonQuestion
    },
]

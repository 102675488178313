var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-bar", { attrs: { title: "成功" } }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img",
        attrs: { src: "/static/img/others/successIcon.png" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "tip_text" }, [
        _vm._v(_vm._s(_vm.lastPage) + "提交成功")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bt" },
        [
          _c(
            "van-button",
            {
              attrs: { size: "large", color: "#00C0A6" },
              on: { click: _vm.confirm }
            },
            [_vm._v("\n            完成\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
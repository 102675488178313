import Router from '@/router/index'
import plugin from '../plugins'
let baseUrl=location.href.slice(0,location.href.indexOf('#')+1)

export default {
    navigateTo(param){
        let reg=/(http|https):\/\/([\w.]+\/?)\S*/;
        if(!reg.test(param.url)){
            param.url=baseUrl+param.url
        }
        plugin.call('navigateTo',param)
    },
    redirectTo(param){
        param.index=1
        plugin.call('closePage',param)
        param.url=baseUrl+this.parseUrl(param.url)
        plugin.call('navigateTo',param)
    },
    reLaunch(param){
        Router.replace(param.url)
    },
    navigateBack(index){
        plugin.call('closePage',index?{index:index}:{})
    },
    openApp(param){
        plugin.call('openApp',param)
    },
    parseUrl(url) {
        if (url.indexOf('?') === -1) {
            return url
        } else {
            var result = {};
            var query = url.split("?");
            var queryArr = query[1].split("&");
            queryArr.forEach(function (item) {
                var value = item.split("=")[1];
                var key = item.split("=")[0];
                result[key]=value;
            });
            localStorage.setItem("routerArgus", JSON.stringify(result))
            return query[0]
        }
    },
    routerArgus() {
        return JSON.parse(localStorage.getItem("routerArgus"))
    }
}

import request from '@/api/http'

const materials = {
    getMaterialsCommodityList(params,callback){
        return request.post(process.env.BASE_URL+`/materials/getMaterialsCommodityList`, params,callback)
    },
    getMaterialsBrandList(params,callback){
        return request.post(process.env.BASE_URL+`/materials/getMaterialsBrandList`, params,callback)
    },
    getMaterialsSpecificationList(params,callback){
        return request.post(process.env.BASE_URL+`/materials/getMaterialsSpecificationList`, params,callback)
    },
    offlineMaterialsApply(params,callback){
        return request.post(process.env.BASE_URL+`/materials/offlineMaterialsApply`, params,callback)
    },
    getMaterialsApplyList(params,callback){
        return request.post(process.env.BASE_URL+`/materials/getMaterialsApplyList`, params,callback)
    },
    getMaterialsApplyDetailList(params,callback){
        return request.post(process.env.BASE_URL+`/materials/getMaterialsApplyDetailList`, params,callback)
    },
    materialsApply(params,callback){
        return request.post(process.env.BASE_URL+`/materials/materialsApply`, params,callback)
    },
    materialsApplyUpdate(params,callback){
        return request.post(process.env.BASE_URL+`/materials/materialsApplyUpdate`, params,callback)
    },
    materialsInventoryLogType(params,callback){
        return request.post(process.env.BASE_URL+`/materials/materialsInventoryLogType`, params,callback)
    },
    materialsInventoryEnd(params,callback){
        return request.post(process.env.BASE_URL+`/materials/materialsInventoryEnd`, params,callback)
    },
    addRepair(params,callback){
        return request.post(process.env.BASE_URL+`/materials/addRepair`, params,callback)
    },
    addScrap(params,callback){
        return request.post(process.env.BASE_URL+`/materials/addScrap`, params,callback)
    },
    queryRepair(params,callback){
        return request.post(process.env.BASE_URL+`/materials/queryRepair`, params,callback)
    },
    queryScrap(params,callback){
        return request.post(process.env.BASE_URL+`/materials/queryScrap`, params,callback)
    },
}


export default materials

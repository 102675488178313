import axios from 'axios'
const {aplus_queue} = window;

export default function (param) {
    if (param.hasOwnProperty('header')) {
        if (param.header.hasOwnProperty('Cookie'))
            delete param.header.Cookie;
    }
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
                console.log("错误回调", error);
                return Promise.reject(error);          // reject这个错误信息
            }
            return Promise.reject(error);
        });

    axios({
        "method": param.method || 'POST',
        "url": param.url,
        "data": param.data,
        "headers": param.header
    }).then(function (response) {
        param.success(response)
    }).catch(res=>{
        if(param.fail){
            param.fail(res)
        }
        console.error('request error',res)
        aplus_queue.push({
            action: 'aplus.record',
            arguments: ['REQUEST_ERROR', 'ERROR', {
                "method": param.method || 'POST',
                "url": param.url,
                "data": JSON.stringify(param.data),
                "headers": JSON.stringify(param.header),
                "res":JSON.stringify(res)
            }]
        });
    })
}

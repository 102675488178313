import request from '@/api/http'

const questionnaire = {
    // 获取相应问卷的问题列表
    getQuestionnaireList(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/getQuestionnaireList`, params, callback)
    },
    // 提交答卷
    commitAnswerSheet(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/addAnswer`, params, callback)
    },
    // 提交区域日报
    commitAreaAnswerSheet(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/addAreaAnswer`, params, callback)
    },
    // 获取答卷列表
    getAnswerSheetList(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/getAnswerList`, params, callback)
    },
    // 获取答卷列表（包括明细）
    getAnswerSheetListIncludeDetail(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/getAnswerAndDetailList`, params, callback)
    },
    // 获取相关区域答卷列表
    getAnswerSheetListByOrgList(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/getOrganizationAnswerList`, params, callback)
    },
    // 获取答卷列表
    getAnswerList(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/getAnswerDetailList`, params, callback)
    },
    // 获取流程问题列表
    getAnswerIssuesList(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/getAnswerIssuesList`, params, callback)
    },
    // 根据id列表获取答题列表
    getAnswerDetailListByIdList(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/getAnswerDetailListByIdList`, params, callback)
    },
    // 添加问题处理记录
    addAnswerIssues(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/addAnswerIssues`, params, callback)
    },
    // 获取未提交项目点
    getUnCommittedProject(params, callback) {
        let callbackInterceptor=data=>{
            if(data==='fail'){
                callback(data)
            }else{
                let data0=data
                data0.list=data.list.map(item=>{
                    let projectManager=item.projectManager
                    if(item.projectRelList==null||item.projectRelList.length<1){
                        item.projectManager='暂无绑定主管'
                    }else{
                        item.projectManager=item.projectRelList[0].employeeName
                        for(let i=1;i<item.projectRelList.length;i++){
                            item.projectManager+='/'+item.projectRelList[i].employeeName
                        }
                    }
                    if(item.projectManager==='暂无绑定主管'&&projectManager!=null){
                        item.projectManager=projectManager
                    }
                    return item
                })
                callback(data0)
            }
        }
        return request.post(process.env.BASE_URL + `/employee/getUncommitedProject`, params, callbackInterceptor)
    },
}

export default questionnaire
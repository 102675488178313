/***    发票    ***/
const invoiceHistory = () => import(/* webpackChunkName: "invoice" */ "@/views/invoice/InvoiceHistory")
const commitInvoice = () => import(/* webpackChunkName: "invoice" */ "@/views/invoice/CommitInvoice")

export default [
    {
        path: '/invoiceHistory',
        name: 'invoiceHistory',
        meta: {
            index: 3,
            title: "开票历史",
            useApp: true,
            needLogin:true
        },
        component: invoiceHistory,
    },
    {
        path: '/commitInvoice',
        name: 'commitInvoice',
        meta: {
            index: 4,
            title: "开票"
        },
        component: commitInvoice,
    },
]
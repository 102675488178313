/***    公告    ***/
const announceHistory = () => import(/* webpackChunkName: "announce" */ "@/views/announce/announceHistory")

export default [
    {
        path: '/announceHistory',
        name: 'announceHistory',
        meta: {
            index: 3,
            title: "公告"
        },
        component: announceHistory
    },
]
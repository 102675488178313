import request from '@/api/http'

const materialsAudit = {
    materialsAuditType(params,callback){
        return request.post(process.env.BASE_URL+`/materialsAudit/materialsAuditType`, params,callback)
    },
    getMaterialsAuditList(params,callback){
        return request.post(process.env.BASE_URL+`/materialsAudit/getMaterialsAuditList`, params,callback)
    },
}


export default materialsAudit
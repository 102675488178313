import navigation from './modules/navigation'
import request from './modules/request'
import system from './modules/system'
import analytics from './modules/analytics'
import push from './modules/push'
import windowConfig from './modules/windowConfig'

export default {
    ...navigation,
    ...system,
    request,
    analytics,
    push,
    windowConfig,
}
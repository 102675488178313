/***    主页    ***/
const ProcessHome = () => import(/* webpackChunkName: "process" */ "@/views/process/ProcessHome")
const NewProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/NewProcess")
const ProcessDeposit = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/ProcessDeposit")
const MyProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/MyProcess")
const MyReview = () => import(/* webpackChunkName: "process" */ "@/views/process/MyReview")
const Error = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/Error")
const ProcessProjectEnter = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/project/ProcessProjectEnter")
const ProcessProjectExit = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/project/ProcessProjectExit")
const ProcessProjectTransfer = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/project/ProcessProjectTransfer")
const ProcessContractSell = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/contract/ProcessContractSell")
const ProcessSupplementaryContract = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/contract/ProcessSupplementaryContract")
const CashReimbursement = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/finance/CashReimbursement")
const FinanceLoan = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/finance/FinanceLoan")
const FinancePayment = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/finance/FinancePayment")
const FinanceTravel = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/finance/FinanceTravel")
const FinanceSuffered = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/finance/FinanceSuffered")
const CustomProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/other/CustomProcess")
const SealProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/other/SealProcess")

export default [
    {
        path: '/process',
        name: 'ProcessHome',
        meta: {
            index: 3,
            title: "流程中心"
        },
        children:[
            {
                path: '/process/my_process',
                name: 'MyProcess',
                meta: {
                    index: 5,
                    title: "我的流程"
                },
                component: MyProcess,
            },
            {
                path: '/process/my_review',
                name: 'MyReview',
                meta: {
                    index: 4,
                    title: "我的流程"
                },
                component: MyReview,
            },
            {
                path: 'home',
                name: 'NewProcess',
                meta: {
                    index: 4,
                    title: "新申请"
                },
                component: NewProcess,
            },
        ],
        component: ProcessHome,
    },

    {
        path: '/process/deposit/:type/:id?',
        name: 'ProcessDeposit',
        meta: {
            index: 6,
            title: "投标及履约保证金"
        },
        component: ProcessDeposit,
    },
    {
        path: '/process/error/:type/:id?',
        name: 'Error',
        meta: {
            index: 6,
            title: "提示页面"
        },
        component: Error,
    },
    {
        path: '/process/project_enter/:type/:id?',
        name: 'ProcessProjectEnter',
        meta: {
            index: 6,
            title: "项目进场"
        },
        component: ProcessProjectEnter,
    },
    {
        path: '/process/project_exit/:type/:id?',
        name: 'ProcessProjectExit',
        meta: {
            index: 6,
            title: "项目退场"
        },
        component: ProcessProjectExit,
    },
    {
        path: '/process/project_transfer/:type/:id?',
        name: 'ProcessProjectTransfer',
        meta: {
            index: 6,
            title: "项目转区域"
        },
        component: ProcessProjectTransfer,
    },
    {
        path: '/process/contract_sell/:type/:id?',
        name: 'ProcessContractSell',
        meta: {
            index: 6,
            title: "销售合同评审流程"
        },
        component: ProcessContractSell,
    },
    {
        path: '/process/supplementary-contract/:type/:id?',
        name: 'ProcessSupplementaryContract',
        meta: {
            index: 6,
            title: "合同补充协议流程"
        },
        component: ProcessSupplementaryContract,
    },
    {
        path: '/process/finance_cash/:type/:id?',
        name: 'CashReimbursement',
        meta: {
            index: 6,
            title: "现金报销申请"
        },
        component: CashReimbursement,
    },
    {
        path: '/process/finance_loan/:type/:id?',
        name: 'FinanceLoan',
        meta: {
            index: 6,
            title: "借款申请"
        },
        component: FinanceLoan,
    },
    {
        path: '/process/finance_payment/:type/:id?',
        name: 'FinancePayment',
        meta: {
            index: 6,
            title: "费用支付申请"
        },
        component: FinancePayment,
    },
    {
        path: '/process/finance_travel/:type/:id?',
        name: 'FinanceTravel',
        meta: {
            index: 6,
            title: "差旅费报销申请"
        },
        component: FinanceTravel,
    },
    {
        path: '/process/finance_suffered/:type/:id?',
        name: 'FinanceSuffered',
        meta: {
            index: 6,
            title: "工伤赔付申请"
        },
        component: FinanceSuffered,
    },
    {
        path: '/process/seal/:type/:id?',
        name: 'SealProcess',
        meta: {
            index: 6,
            title: "用印申请流程"
        },
        component: SealProcess,
    },
    {
        path: '/process/custom/:type/:id?',
        name: 'CustomProcess',
        meta: {
            index: 6,
            title: "自定义流程"
        },
        component: CustomProcess,
    },
]

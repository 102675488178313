/***                  日志                    ***/

/***    日报    ***/
const DayReportHome = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/DayReportHome")
const ProjectDayReportNew = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/project/ProjectDayReportNew")
const ProjectDayReportDetail = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/project/ProjectDayReportDetail")
const DayReportProblem = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/common/DayReportProblem")
const AreaDayReportStatistic = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/area/AreaDayReportStatistic")
const AreaDayReportNew = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/area/AreaDayReportNew")
const DepartmentDayReportStatistic = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/department/DepartmentDayReportStatistic")
const ManagerDayReportStatistic = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/manager/ManagerDayReportStatistic")
const ManagerDayReportQuestionList = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/manager/ManagerDayReportQuestionList")
const DayReportProblemList = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/dayReport/common/DayReportProblemList")

/***    遗弃    ***/
// const NewReport = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/NewReport")  //暂时不用
// const ReportHistory = () => import(/* webpackChunkName: "dayReport" */ "@/views/report/ReportHistory") //暂时不用

export default [
    {
        path: '/report/dayReport/home',
        name: 'DayReportHome',
        meta: {
            index: 3,
            title: "日报"
        },
        component: DayReportHome,
        props: true
    },
    {
        path: '/report/project/new',
        name: 'ProjectDayReportNew',
        meta: {
            index: 4,
            title: "新建项目日报"
        },
        component: ProjectDayReportNew,
    },
    {
        path: '/report/project/detail/:answerSheetId',
        name: 'ProjectDayReportDetail',
        meta: {
            index: 9,
            title: "项目日报详情"
        },
        component: ProjectDayReportDetail,
        props: true
    },
    {
        path: '/report/question/:answerId',
        name: 'DayReportProblem',
        meta: {
            index: 10,
            title: "问题"
        },
        component: DayReportProblem,
        props: true
    },
    {
        path: '/report/area/new',
        name: 'AreaDayReportNew',
        meta: {
            index: 4,
            title: "区域提交"
        },
        component: AreaDayReportNew,
    },
    {
        path: '/report/area/statistic',
        name: 'AreaDayReportStatistic',
        meta: {
            index: 4,
            title: "区域统计"
        },
        component: AreaDayReportStatistic,
    },
    {
        path: '/report/department/statistic',
        name: 'DepartmentDayReportStatistic',
        meta: {
            index: 4,
            title: "职能部门统计"
        },
        component: DepartmentDayReportStatistic,
    },
    {
        path: '/report/manager/statistic',
        name: 'ManagerDayReportStatistic',
        meta: {
            index: 4,
            title: "管理员统计"
        },
        component: ManagerDayReportStatistic,
    },
    {
        path: '/report/manager/questionList',
        name: 'ManagerDayReportQuestionList',
        meta: {
            index: 5,
            title: "管理员回答列表"
        },
        component: ManagerDayReportQuestionList,
    },
    {
        path: '/report/common/problemList',
        name: 'DayReportProblemList',
        meta: {
            index: 5,
            title: "问题列表"
        },
        component: DayReportProblemList,
    },
    // {
    //     path: '/report/reportHistory',
    //     name: 'ReportHistory',
    //     meta: {
    //         index: 3,
    //         title: "日志历史"
    //     },
    //     component: ReportHistory,
    // },
    // {
    //     path: '/report/newReport/:type',
    //     name: 'NewReport',
    //     meta: {
    //         index: 3,
    //         title: "日志"
    //     },
    //     component: NewReport,
    //     props: true
    // },
]
<template>
    <van-overlay :show="show"
                 @click="$emit('showChange',false)"
    >
        <transition :name="transitionName">
            <div class="popover_container"
                 ref="contentRef">
                <div class="popover_triangle"
                     :style="{top:triangle.top+'px',left:triangle.left+'px',borderWidth:triangleWidth/2+'px',borderColor:triangle.class}"
                ></div>
                <div class="popover_content"
                     :style="{top:content.top+'px',left:content.left+'px',width:contentWidth+'px'}">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </van-overlay>
</template>

<script>
    export default {
        name: "NutPopover",
        props: {
            show: {
                default: false
            },
            position: {
                default: "bottom"// top / bottom / left / right
            },
            root: {
                required: true
            },
            triangleWidth: {
                default: 20
            },
            contentWidth: {
                default: 125
            }
        },
        model: {
            prop: 'show',
            event: 'showChange'
        },
        data() {
            return {
                transitionName: "van-slide-down",
                triangle: {
                    top: 0,
                    left: 0,
                    class: "transparent transparent white transparent",
                },
                content: {
                    top: 0,
                    left: 0,
                },
                contentHeight: 0
            }
        },
        watch: {
            position() {
                this.calcPosition()
            },
            root() {
                this.calcPosition()
            },
        },
        mounted() {
            this.contentHeight = this.$refs.contentRef.offsetHeight
        },
        methods: {
            calcPosition() {
                let startPoint = {
                    x: 0,
                    y: 0
                }
                let contentHeight = this.contentHeight
                let offsetParent = this.root.offsetParent == null ? {
                    offsetLeft: 0,
                    offsetTop: 0
                } : this.root.offsetParent
                switch (this.position) {
                    case 'top':
                        this.triangle.class = "white transparent transparent transparent"
                        this.transitionName = "van-slide-down"
                        startPoint = {
                            y: this.root.offsetTop,
                            x: this.root.offsetLeft + offsetParent.offsetLeft + this.root.clientWidth / 2
                        }
                        this.triangle.top = startPoint.y - this.triangleWidth / 2
                        this.triangle.left = startPoint.x - this.triangleWidth / 2
                        this.content.top = startPoint.y - this.triangleWidth / 2 - contentHeight
                        this.content.left = startPoint.x - this.contentWidth / 2
                        if (this.content.left + this.contentWidth + 5 > document.body.clientWidth) {
                            this.content.left = document.body.clientWidth - this.contentWidth - 5
                        }
                        if (this.content.left < 5) {
                            this.content.left = 5
                        }
                        break
                    case 'left':
                        this.triangle.class = "transparent transparent transparent white"
                        this.transitionName = "van-slide-left"
                        startPoint = {
                            y: this.root.offsetTop + this.root.clientHeight / 2,
                            x: this.root.offsetLeft + offsetParent.offsetLeft
                        }
                        this.triangle.top = startPoint.y - this.triangleWidth / 2
                        this.triangle.left = startPoint.x - this.triangleWidth / 2
                        this.content.top = startPoint.y - contentHeight / 2
                        this.content.left = startPoint.x - this.contentWidth - this.triangleWidth / 2
                        break
                    case 'right':
                        this.triangle.class = "transparent white transparent transparent"
                        this.transitionName = "van-slide-right"
                        startPoint = {
                            y: this.root.offsetTop + this.root.clientHeight / 2,
                            x: this.root.offsetLeft + offsetParent.offsetLeft + this.root.clientWidth
                        }
                        this.triangle.top = startPoint.y - this.triangleWidth / 2
                        this.triangle.left = startPoint.x - this.triangleWidth / 2
                        this.content.top = startPoint.y - contentHeight / 2
                        this.content.left = startPoint.x + this.triangleWidth / 2
                        break
                    case 'bottom':
                    default:
                        this.triangle.class = "transparent transparent white transparent"
                        this.transitionName = "van-slide-up"
                        startPoint = {
                            y: this.root.offsetTop + offsetParent.offsetTop + this.root.offsetHeight,
                            x: this.root.offsetLeft + offsetParent.offsetLeft + this.root.clientWidth / 2
                        }
                        this.triangle.top = startPoint.y - this.triangleWidth / 2
                        this.triangle.left = startPoint.x - this.triangleWidth / 2
                        this.content.top = startPoint.y + this.triangleWidth / 2
                        this.content.left = startPoint.x - this.contentWidth / 2
                        if (this.content.left + this.contentWidth + 5 > document.body.clientWidth) {
                            this.content.left = document.body.clientWidth - this.contentWidth - 5
                        }
                        if (this.content.left < 5) {
                            this.content.left = 5
                        }
                }
            }
        }
    }
</script>

<style scoped>

    .popover_container {
        position: relative;
    }

    .popover_content {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
    }

    .popover_triangle {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border: 10px solid transparent;
    }
</style>
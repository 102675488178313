import request from '@/api/http'

const processAPI = {
    // 项目进场申请
    addProjectApproachApply(params, callback) {
        return request.post(process.env.BASE_URL + `/project/addProjectApproachApply`, params, callback)
    },
    // 项目退场
    addProjectQuitApply(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addProjectQuitApply', params, callback)
    },
    // 项目转区域添加
    addProjectAreaChange(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addProjectAreaChange', params, callback)
    },
    // （新增/续签）合同流程
    addProjectContractNews(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addProjectContractNews', params, callback)
    },
    // 新增合同补充协议流程
    addProjectContractAgreement(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addProjectContractAgreement', params, callback)
    },
    // 自定义流程
    addCustomProcess(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addCustomProcess', params, callback)
    },
    // 用印申请流程
    addSealProcess(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addSealProcess', params, callback)
    },
    // 流程审核 （拒绝/通过）
    workProcessAudit(params, callback) {
        return request.post(process.env.BASE_URL + '/project/workProcessAudit', params, callback)
    },
    // 流程审核 （会审）
    workProcessMeeting(params, callback) {
        return request.post(process.env.BASE_URL + '/project/workProcessMeeting', params, callback)
    },
    // 流程审核 （移交他人）
    workProcessChange(params, callback) {
        return request.post(process.env.BASE_URL + '/project/workProcessChange', params, callback)
    },
    // 获取收款人列表（报销）
    getPayeeList(params, callback) {
        return request.post(process.env.BASE_URL + '/materials/getPayeeList', params, callback)
    },
    //添加现金报销申请
    addCashReimbursement(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addCashReimbursement', params, callback)
    },
    //添加借款申请
    addBorrowMoney(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addBorrowMoney', params, callback)
    },
    //费用支付申请
    addCostPay(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addCostPay', params, callback)
    },
    //差旅费报销申请
    addTravelReimbursement(params, callback) {
        return request.post(process.env.BASE_URL + '/project/addTravelReimbursement', params, callback)
    },
}

export default processAPI

<template>
  <div class="banSelect">
    <transition
        appear-class="van-fade"
        :enter-class="transition.enterClass"
        :enter-active-class="transition.enterActiveClass"
        :enter-to-class="transition.enterToClass"
        :leave-class="transition.leaveClass"
        :leave-active-class="transition.leaveActiveClass"
        :leave-to-class="transition.leaveToClass"
        v-if="!isPc"
        :mode="transition.mode">
      <keep-alive :include="includeList">
        <router-view class="appBody">
        </router-view>
      </keep-alive>
    </transition>
    <div v-else class="phone_tip">
      <div>
        请使用手机打开
      </div>
    </div>
    <div v-if="$loginStatus.isDebug"
         class="debug_bt"
         ref="debugBtRef"
         @click="debugBtPopoverShow=!debugBtPopoverShow">
    </div>
    <nut-popover v-model="debugBtPopoverShow"
                 position="top"
                 :root="$refs.debugBtRef">
      <div>
        <van-field label="主管账号"
                   disabled
                   @click="changeLogin('projectManager')"
                   clickable></van-field>
        <van-field label="区域综管"
                   disabled
                   @click="changeLogin('areaChange')"
                   clickable></van-field>
        <van-field label="区域经理"
                   disabled
                   @click="changeLogin('areaManager')"
                   clickable></van-field>
        <van-field label="超级管理员"
                   disabled
                   @click="changeLogin('superManager')"
                   clickable></van-field>
      </div>
    </nut-popover>
  </div>
</template>

<script>
const localVersion = 2.7

import BasePage from '@/views/components/BasePage'
import oldNw from '@/nvwa/version1_0/android'
import LoginStatusMixin from "@/store/modules/moduleLoginStatus/mixin/LoginStatusMixin";
import NutPopover from "@/components/NutPopover";
const {aplus_queue} = window;

export default {
  name: "app",
  components: {NutPopover},
  extends: BasePage,
  mixins: [LoginStatusMixin],
  watch: {
    '$route'(to, from) {
      aplus_queue.push({
        action: 'aplus.sendPV',
        arguments: [{ is_auto: false } , {
          page_name:to.fullPath
        }]
      });
      const toDepth = to.meta.index
      const fromDepth = from.meta.index || -1
      if (toDepth >= fromDepth) {
        to.matched.forEach(marchedItem => {
          if (!this.includeAllList.some(item => {
            return item.name === marchedItem.name
          })) {
            this.includeAllList.push(marchedItem)
          }
        })
        this.changeTransition(true)
      } else if (toDepth < fromDepth) {
        this.includeAllList = this.includeAllList.filter(item => {
          return item.meta.index <= toDepth;
        })
        this.changeTransition(false)
      }
      this.includeList = this.includeAllList.map(item => {
        return item.name
      })
      if (fromDepth === toDepth) {
        if (to.meta.subIndex < from.meta.subIndex) {
          this.changeTransition(false)
        }
      }
      if (fromDepth === -1) {
        this.changeTransition(true)
      }
    },
  },
  data() {
    return {
      debugBtPopoverShow: false,
      transition: {
        enterClass: '',
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: "",
        mode: null
      },
      transitionName: "van-slide-right",
      includeAllList: [{
        name: "home",
        meta: {
          index: 1
        }
      }],
      includeList: [],
      appHeight: "",
      isPc: this.$nw.UA.isPc
    }
  },
  created() {
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['globalproperty', {'UA_TYPE':this.$nw.UA.type,isNvwa:this.$nw.UA.isNvwa}, 'APPEND']
    });
    window.IOSBackClick = false
    this.$nw.windowConfig({
      params: {
        hideActionBar: true
      }
    })
    if (localStorage.getItem("localVersion") == null || localStorage.getItem("localVersion") < localVersion) {
      localStorage.setItem("localVersion", localVersion)
      this.$store.dispatch('loginStatus/logout').then(
          () => {
            this.$router.replace('/')
          }
      )
    }
    this.checkUpdate()
  },
  methods: {
    changeTransition(enter) {
      if (enter) {
        this.transition = {
          enterClass: 'van-slide-right-enter',
          enterActiveClass: 'van-slide-right-enter-active',
          enterToClass: "van-slide-right-enter-to",
          leaveClass: "van-fade-leave",
          leaveActiveClass: "van-fade-leave-active",
          leaveToClass: "van-fade-leave-to",
          mode: "out-in"
        }
      } else {
        if (this.$nw.UA.isIOS && !IOSBackClick) {
          window.IOSBackClick = false
          this.transition = {
            enterClass: null,
            enterActiveClass: null,
            enterToClass: null,
            leaveClass: null,
            leaveActiveClass: null,
            leaveToClass: null,
            mode: "out-in"
          }
        } else {
          this.transition = {
            enterClass: 'van-fade-enter',
            enterActiveClass: 'van-fade-enter-active',
            enterToClass: "van-fade-enter-to",
            leaveClass: "van-slide-right-leave",
            leaveActiveClass: "van-slide-right-leave-active",
            leaveToClass: "van-slide-right-leave-to",
            mode: "out-in"
          }
        }
      }
    },
    checkUpdate() {
      if (this.$nw.UA.isOldNvwa && this.$nw.UA.isAndroid) {
        let tipUpdate = () => {
          this.$dialog.alert({
            title: '当前版本必须更新',
            message:
                '1.完善当前已有功能\n' +
                '2. 升级更新方式，缩小所需安装包体积\n' +
                '3.统一主题，优化使用界面\n' +
                '点击确定进入下载界面'
          }).then(() => {
            this.checkUpdate()
            oldNw.openBrowser({
              url: 'https://fir.im/8gzr'
            })
          });
        }

        oldNw.plugin.call('update', {versionName: '2.44'},
            data => {
              if (data.param === "当前已是最新版本") {
                tipUpdate()
                return
              }
              if (parseFloat(data.param) < 3.0) {
                tipUpdate()
              }
            }
        )
      }
      if (this.$nw.UA.isOldNvwa && this.$nw.UA.isIOS) {
        let tipUpdate = () => {
          this.$dialog.alert({
            title: '当前版本必须更新',
            message:
                '1.完善当前已有功能\n' +
                '2. 升级更新方式，缩小所需安装包体积\n' +
                '3.统一主题，优化使用界面\n' +
                '点击确定进入下载界面'
          }).then(() => {
            this.checkUpdate()
            this.$router.push('/downloadApp')
          });
        }
      }
    },
    deleteFromArray(key, list) {
      let keyIndex = 0
      list.forEach(function (item, index) {
        if (key === item) {
          keyIndex = index
        }
      })
      list.splice(keyIndex, 1)
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    changeLogin(bt) {
      let encrypt = (word) => {
        let key = CryptoJS.enc.Utf8.parse("lvjingcleaning12");
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
      }
      switch (bt) {
        case 'projectManager':
          this.$toast.loading({
            duration: 0
          })
          this.$store.dispatch('loginStatus/login', {
            loginName: '18257118042',
            passWord: encrypt('123456'),
            isActive: 'Y'
          }).then((data) => {
            this.$toast.success()
            if (data.code == 0) {
            } else {
              this.$notify(data.msg)
            }
          }).catch(() => {
            this.$notify('请重试')
          })
          break
        case 'areaChange':
          this.$toast.loading({
            duration: 0
          })
          this.$store.dispatch('loginStatus/login', {
            loginName: '13750830423',
            passWord: encrypt('123456'),
            isActive: 'Y'
          }).then((data) => {
            this.$toast.success()
            if (data.code == 0) {
            } else {
              this.$notify(data.msg)
            }
          }).catch(() => {
            this.$notify('请重试')
          })
          break
        case 'areaManager':
          this.$toast.loading({
            duration: 0
          })
          this.$store.dispatch('loginStatus/login', {
            loginName: '13958161017',
            passWord: encrypt('123456'),
            isActive: 'Y'
          }).then((data) => {
            this.$toast.success()
            if (data.code == 0) {
            } else {
              this.$notify(data.msg)
            }
          }).catch(() => {
            this.$notify('请重试')
          })
          break
        case 'superManager':
          this.$toast.loading({
            duration: 0
          })
          this.$store.dispatch('loginStatus/login', {
            loginName: 'supadmin',
            passWord: encrypt('Lvjing123'),
            isActive: 'Y'
          }).then((data) => {
            this.$toast.success()
            if (data.code == 0) {
            } else {
              this.$notify(data.msg)
            }
          }).catch(() => {
            this.$notify('请重试')
          })
          break
      }
    }
  }
}
</script>

<style scoped>

.appBody {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.banSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.phone_tip {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.debug_bt {
  z-index: 99999;
  width: 40px;
  height: 40px;
  background-color: #00c0a6;
  position: fixed;
  right: 40px;
  bottom: 80px;
}
</style>

import routes from './routes'
import VueRouter from 'vue-router'
import {Notify, Dialog, Toast} from 'vant'

const router = new VueRouter({
    base: "/",
    routes: routes

})

import store from '@/store'
import nw from '@/nvwa/versionLast/index'

router.beforeEach((to,from,next)=>{
    if (getQueryVariable("index")) {
        console.log('getQueryVariable')
        console.log(getQueryVariable("index"))
        window.location.replace(location.href.slice(0, location.href.indexOf('?') - 1) + "/#" + getQueryVariable("index"))
        // router.replace(getQueryVariable("index"))
        // next('/'+getQueryVariable("index"))
        return
    }
    next()
})

router.afterEach((to, from) => {
    Dialog.close()
    Notify.clear()
    Toast.clear()
    let loginAuth = () => {
        if (to.meta.needLogin && !store.getters['loginStatus/hasLogin']()) {
            Dialog.alert({
                title: '提示',
                message: '请先登录'
            }).then(() => {
                router.push('/afterLogin')
                // on close
            });
        }
    }
    if (!nw.UA.isNvwa && to.meta.useApp) {
        if (!to.meta.needLogin || store.getters['loginStatus/hasLogin']()) {
            Dialog.confirm({
                title: "提示",
                message: "点击确定下载APP使用"
            }).then(() => {
                router.push('/downloadApp')
            }).catch(() => {
                loginAuth()
            })

        }
    }
    loginAuth()
})

export default router

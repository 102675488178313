import $api from '@/api'

export default {
    state:{
        list:[]
    },
    mutations:{
        updateMenuInfo(state, menuList) {
            localStorage.setItem("menuList", JSON.stringify(menuList))
            state.list = menuList
        },
        init(state){
            let menuList=JSON.parse(localStorage.getItem("menuList"))
            if(menuList==null){
                return
            }
            state.list = menuList
        }
    },
    getters:{
        hasMenu:(state) => (permissionKeyword) => {
            let menuList = state.list
            if (menuList == null) {
                return false
            }
            let getMenuChild=function(menuList) {
                return menuList.some(item => {
                    if (item.permissionKeyword != null && item.permissionKeyword === permissionKeyword) {
                        return true
                    }
                    if (item.children != null) {
                        return getMenuChild(item.children, permissionKeyword)
                    }
                    return false
                })
            }
            return getMenuChild(menuList)
        },
        getMenuById:(state) => (menuId) => {
            let menuList = state.list
            if (menuList == null) {
                return false
            }
            let getMenuChild = function (menuList) {
                return menuList.some(item => {
                    if (item.menuId != null && item.menuId === menuId) {
                        return true
                    }
                    if (item.children != null) {
                        return getMenuChild(item.children)
                    }
                    return false
                })
            }
            return getMenuChild(menuList)
        }
    },
    actions: {
        init({dispatch, state, rootState}) {
            dispatch('login', {
                loginName: rootState.loginStatus.userInfo.loginName,
                passWord: localStorage.getItem("password"),
                isActive: 'Y'
            }).then(res => {
                console.log('更新登录信息')
            })
        }
    }
}

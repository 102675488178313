import request from '@/api/http'

const materialsSupplier = {
    getMaterialsSupplierList(params,callback){
        return request.post(process.env.BASE_URL+`/materialsSupplier/getMaterialsSupplierList`, params,callback)
    },
    getSupplierDetail(params,callback){
        return request.post(process.env.BASE_URL+`/materialsSupplier/getSupplierDetail`, params,callback)
    }
}


export default materialsSupplier
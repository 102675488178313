<template>
    <div>
        <action-bar title="成功"></action-bar>
        <img class="img"
             src="/static/img/others/successIcon.png">
        <div class="tip_text">{{lastPage}}提交成功</div>
        <div class="bt">
            <van-button size="large"
                        @click="confirm"
                        color="#00C0A6">
                完成
            </van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "success",
        data() {
            return {
                lastPage: ''
            }
        },
        beforeRouteEnter(to, from, next) {
            let title = ""
            from.matched.forEach(item => {
                title = item.meta.title || title
            })
            next(vm => {
                vm.lastPage = title
            })
        },
        methods: {
            confirm() {
                this.$nw.navigateBack()
            }
        }
    }
</script>

<style scoped>
    .img {
        margin-top: 62px;
        margin-left: 138px;
        width: 100px;
        height: 100px;
    }

    .tip_text {
        margin-top: 24px;
        width: 375px;
        height: 18px;
        font-size: 18px;
        color: #666;
        font-weight: bold;
        text-align: center;
    }

    .bt {
        margin-top: 62px;
        width: 315px;
        height: 43px;
        margin-left: 30px;
    }
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-bar", {
        ref: _vm.CONSTANTS.ACTION_BAR_REF,
        attrs: {
          "background-color": "rgba(52,52,52,0)",
          border: false,
          title: _vm.selfActionBar.title,
          backgroundImg: _vm.selfActionBar.backgroundImg
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "background_img",
        attrs: { src: "/static/img/others/employeeCard/background_img.jpg" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.employeeInfo.employeeName))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name" }, [
        _c("div", [_vm._v(_vm._s(_vm.employeeInfo.orgName))]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.employeeInfo.positionType))])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "org" }, [
        _c("div", { staticClass: "label" }, [_vm._v("所属部门")]),
        _vm._v(" "),
        _c("div", { staticClass: "cell_body" }, [
          _vm._v(_vm._s(_vm.employeeInfo.orgName))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "position" }, [
        _c("div", { staticClass: "label left" }, [_vm._v("职位")]),
        _vm._v(" "),
        _c("div", { staticClass: "cell_body left" }, [
          _vm._v(_vm._s(_vm.employeeInfo.positionType))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tab_box" }, [
        _c("div", { staticClass: "tab", on: { click: _vm.sendMsg } }, [
          _c("img", {
            staticClass: "bt",
            attrs: { src: "/static/img/others/employeeCard/msg.png" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._v("发消息")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tab", on: { click: _vm.phone } }, [
          _c("img", {
            staticClass: "bt",
            attrs: { src: "/static/img/others/employeeCard/phone.png" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._v("打电话")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tab", on: { click: _vm.email } }, [
          _c("img", {
            staticClass: "bt",
            attrs: { src: "/static/img/others/employeeCard/email.png" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._v("发邮件")])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar_box" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: {
          src: "https://publicapp.oss-cn-hangzhou.aliyuncs.com/avatar/head1.jpg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
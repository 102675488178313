import request from '@/api/http'

const invoice = {
    getClockList(params,callback){
        return request.post(process.env.BASE_URL+`/employee/tmp/getTmpEmployeeClockList`, params,callback)
    },
    getGroupClockList(params,callback){
        return request.post(process.env.BASE_URL+`/employee/tmp/getClockList`, params,callback)
    },
    addClock(params,callback){
        return request.post(process.env.BASE_URL+`/employee/tmp/addTmpEmployeeClock`, params,callback)
    },
    getPersonalStatistic(params,callback){
        return request.get(process.env.BASE_URL+`/employee/tmp/personalStatistics`, params,callback)
    },
    getGroupStatistic(params,callback){
        return request.get(process.env.BASE_URL+`/employee/tmp/groupStatistics`, params,callback)
    },
    getOrgStatistic(params,callback){
        return request.get(process.env.BASE_URL+`/employee/tmp/orgStatistics`, params,callback)
    },
    getUnClockList(params,callback){
        return request.post(process.env.BASE_URL+`/employee/tmp/getUnClockList`, params,callback)
    }
}


export default invoice


const UA = function () {
    let UA = navigator.userAgent.toLowerCase(),
        isAndroid = /android|adr|linux/gi.test(UA),
        isIOS = /iphone|ipod|ipad/gi.test(UA) && !isAndroid,
        isBlackBerry = /blackberry/i.test(UA),
        isWindowPhone = /iemobile/i.test(UA),
        isOldNvwa = /lvjing/i.test(UA),
        isNvwa = /nvwa/i.test(UA),
        isWechat = /micromessenger/.test(UA),
        isPc = function () {
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
            var flag = true;
            for (var v = 0; v < Agents.length; v++) {
                if (userAgentInfo.indexOf(Agents[v]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        isWeChatApplet = function () {
            // 是否为微信小程序
            const ua = window.navigator.userAgent.toLowerCase();
            return new Promise((resolve) => {
                if (ua.indexOf('micromessenger') == -1) {//不在微信或者小程序中
                    resolve(false);
                } else {
                    wx.miniProgram.getEnv((res) => {
                        if (res.miniprogram) {//在小程序中
                            resolve(true);
                        } else {//在微信中
                            resolve(false);
                        }
                    });
                }
            });
        }

    return {
        isAndroid: isAndroid,
        isIOS: isIOS,
        isBlackBerry: isBlackBerry,
        isWindowPhone: isWindowPhone,
        isNvwa: isNvwa,
        isOldNvwa: isOldNvwa,
        isWeChat: isWechat,
        isPc: isPc(),
        isWeChatApplet:isWeChatApplet
    }
}

export default {
    UA: UA()
}

const {aplus_queue} = window;

export default {
    state: {
            "id": null,
            "userNumber": null,
            "userName": null,
            "loginName": null,
            "passWord": null,
            "newPassWord": null,
            "isActive": null,
            "mobile": null,
            "remark": null,
            "createTime": null,
            "updateTime": null,
            "employeeOrgid": null
    },
    mutations: {
        updateUserInfo(state, userInfo) {
            localStorage.setItem("userInfo", JSON.stringify(userInfo))
            Object.keys(userInfo).forEach(key=>{
                state[key]=userInfo[key]
            })
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['_user_id', userInfo.id, 'OVERWRITE']
            });
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['_user_nick', userInfo.userName, 'OVERWRITE']
            });
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['alplus-idtype', 'uuid', 'OVERWRITE']
            });
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['uuid', userInfo.id, 'OVERWRITE']
            });
        },
        init(state) {
            let userInfo=JSON.parse(localStorage.getItem("userInfo"))
            if(userInfo==null){
                return
            }
            Object.keys(userInfo).forEach(key=>{
                state[key]=userInfo[key]
            })
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['_user_id', userInfo.id, 'OVERWRITE']
            });
            aplus_queue.push({
                action: 'aplus.setMetaInfo',
                arguments: ['_user_nick', userInfo.userName, 'OVERWRITE']
            });
        }
    }
}

/***    妇女节    ***/
const WomenDayHome = () => import(/* webpackChunkName: "womenDay" */ "@/views/temp/womenDay/WomenDayHome")
const WomenDayAnswer = () => import(/* webpackChunkName: "womenDay" */ "@/views/temp/womenDay/WomenDayAnswer")
const CountLogin = () => import(/* webpackChunkName: "womenDay" */ "@/views/temp/loginCount/CountLogin")
const Question2Home = () => import(/* webpackChunkName: "question2" */ "@/views/temp/question2/QuestionHome")
const Question2Answer = () => import(/* webpackChunkName: "question2" */ "@/views/temp/question2/Question2Answer")

export default [
    {
        path: '/womenDay/home',
        name: 'WomenDayHome',
        meta: {
            index: 3,
            title: "妇女节快乐",
            needLogin: true
        },
        component: WomenDayHome,
    },
    {
        path: '/question2/answer/new',
        name: 'Question2Answer',
        meta: {
            index: 5,
            title: "答题活动"
        },
        component: Question2Answer,
    },
    {
        path: '/question2/home',
        name: 'Question2Home',
        meta: {
            index: 3,
            title: "答题活动",
            needLogin: true
        },
        component: Question2Home,
    },
    {
        path: '/womenDay/answer/new',
        name: 'WomenDayAnswer',
        meta: {
            index: 4,
            title: "答题"
        },
        component: WomenDayAnswer,
    },
    {
        path: '/count/login',
        name: 'CountLogin',
        meta: {
            index: 4,
            title: "登陆计数"
        },
        component: CountLogin,
    },
]
import Router from '@/router/index'
import nwApi from '../nwApi'

export default {
    navigateTo(param) {
        let reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        param.route=param.url
        if (!reg.test(param.url)) {
            if(location.href.indexOf('?')>0){
                param.route =location.href.slice(0, location.href.indexOf('?'))+"#" + this.parseUrl(param.url)
            }else{
                param.route = location.href.slice(0, location.href.indexOf('#') + 1) + this.parseUrl(param.url)
            }
        }
        nwApi.common('pushSand', {
            params: param,
            success() {
            }
        })
    },
    redirectTo(param) {
        Router.replace(this.parseUrl(param.url))
    },
    navigateBack(param){
        nwApi.common('backPress',param)
    },
    reLaunch(param) {
        Router.replace(param.url)
    },
    parseUrl(url) {
        if (url.indexOf('?') === -1) {
            return url
        } else {
            var result = {};
            var query = url.split("?");
            var queryArr = query[1].split("&");
            queryArr.forEach(function (item) {
                var value = item.split("=")[1];
                var key = item.split("=")[0];
                result[key] = value;
            });
            localStorage.setItem("routerArgus", JSON.stringify(result))
            return query[0]
        }
    },
    routerArgus() {
        return JSON.parse(localStorage.getItem("routerArgus"))
    }
}

export default {
    _ready: function (callback) {
        // 如果jsbridge已经注入则直接调用
        if (window.AlipayJSBridge) {
            callback && callback();
        } else {
            // 如果没有注入则监听注入的事件
            document.addEventListener('JSBridgeReady', callback, false);
        }
    },
    call: function (method, params, callback) {
        this._ready(function () {
            JSBridge.call(method, params, callback||function () {
                
            });
        });
    },
}
/***    项目    ***/
const ProjectHome = () => import(/* webpackChunkName: "project" */ "@/views/project/ProjectHome")
const ProjectDetail = () => import(/* webpackChunkName: "project" */ "@/views/project/ProjectDetail")

export default [
    {
        path: '/project',
        name: 'ProjectHome',
        meta: {
            index: 3,
            title: "项目管理"
        },
        component: ProjectHome,
    },
    {
        path: '/project/detail/:type',
        name: 'ProjectDetail',
        meta: {
            index: 5,
            title: "项目详情"
        },
        component: ProjectDetail,
        props:true
    },
]
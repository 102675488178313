/***    其他    ***/
import downloadApp from '@/views/others/DownloadApp'
import success from '@/views/others/Success'
import EmployeeCard from '@/views/others/EmployeeCard'
import WeChatAppletDownload from '@/views/others/WeChatAppletDownload'

export default [
    {
        path: '/downloadApp',
        name: 'DownloadApp',
        meta: {
            index: 99,
            title: "APP下载"
        },
        component: downloadApp,
    },
    {
        path:'/weChatAppletDownload',
        name:'WeChatAppletDownload',
        mate:{
            index:99,
            title:"小程序下载"
        },
        component: WeChatAppletDownload,
    },
    {
        path: '/success',
        name: 'Success',
        meta: {
            index: 99,
            title: "完成"
        },
        component: success,
    }, {
        path: '/employeeCard',
        name: 'EmployeeCard',
        meta: {
            index: 9,
            title: "名片"
        },
        component: EmployeeCard
    },
]

import request from '@/api/http'

const organizationRelationship = {
    getOrganizationRelList(params,callback){
        return request.post(process.env.BASE_URL+`/organizationRelationship/getOrganizationRelList`, params,callback)
    },
    getProjectConfigList(params,callback){
        return request.post(process.env.BASE_URL+`/project/getProjectConfigList`, params,callback)
    },
}


export default organizationRelationship

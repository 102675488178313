import request from '@/api/http'

const employee = {
    // post提交
    communicationList (params,callback) {
        return request.post(process.env.BASE_URL+`/employee/communicationList`, params,callback)
    },
    // 在职员工列表
    getEmployeeList(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/getEmployeeList`, params,callback)
    },
    // 离职员工列表
    getDepartureEmployeeList(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/getEmployeeCopyList`, params,callback)
    },
    // 员工复职
    reinstatement(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/employeeRecovery`, params,callback)
    },
    // 离职员工列表
    getDepartureEmployeeDetail(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/getEmployeeCopyDetail`, params,callback)
    },
    queryEmployeeInfoById(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/queryEmployeeInfoById`, params,callback)
    },
    insertEmployee(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/insertEmployee`, params,callback)
    },
    updateEmployee(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/updateEmployee`, params,callback)
    },
    queryMarquee(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/queryMarquee`, params,callback)
    },
    insertMarquee(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/insertMarquee`, params,callback)
    },
    getEmployeeListNoCheck(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/getEmployeeListNoCheck`, params,callback)
    },
    employeeQuit(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/employeeDeparture`, params,callback)
    },
    getEmployeeAreaRelList(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/getEmployeeAreaRelList`, params,callback)
    },
    addHealthDayReports(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/addDayReports`, params,callback)
    },
    getHealthDayReports(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/getDayReportsList`, params,callback)
    },
    getUnReportList(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/getUnReportList`, params,callback)
    },
    sendSMS(params,callback) {
        return request.post(process.env.BASE_URL+`/employee/sendSMS`, params,callback)
    }
}

export default employee

import router from './router'
import App from './app.vue';

import store from './store'

store.dispatch('loginStatus/init')
store.commit('init')

// *************      组件库         *******
import Vant from 'vant';
// import 'vant/lib/index.css';
Vue.use(Vant);
import {Notify, Lazyload, ImagePreview, Dialog,Toast} from 'vant'
Vue.use(Lazyload).use(ImagePreview).use(Dialog)
import './assets/global.css'

Notify.setDefaultOptions({
    duration: 1500,
})
Dialog.setDefaultOptions({
    width: "250px",
    transition: "van-slide-up",
    confirmButtonColor: "#00C0A6"
})
Toast.setDefaultOptions("loading",{
    duration:0,
    forbidClick: true
})


window.eventBus = new Vue();

import api from './api'
import nw from '@/nvwa/versionLast'

Vue.prototype.$fetch = api
Vue.prototype.$nw = nw

// 剪切板
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard)

import actionBar from '@/components/NutActionBar'
Vue.component('action-bar', actionBar)

// 加入到vue实例中
new Vue({
    el: '#app',
    router: router,
    store,
    render: (c) => {
        return c(App);
    },
});



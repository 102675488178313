import push from './modules/push'
import analytics from './modules/analytics'
import windowConfig from './modules/windowConfig'
import navigation from './modules/navigation'
import system from './modules/system'

//*********     直接使用android   *********
//*********     直接使用web    *********
import request from '../web/modules/request'

export default {
    ...navigation,
    push,
    windowConfig,
    analytics,
    request,
    ...system,
}
export default {
    state: {
        list: [{
            "id": null,
            "roleName": null,
            "remark": null,
            "createTime": null,
            "isActive": null,
            "roleList": null
        }]
    },
    mutations: {
        updateRoleInfo(state, roleInfo) {
            localStorage.setItem("roleInfo", JSON.stringify(roleInfo))
            state.list = roleInfo
        },
        init(state) {
            let roleInfo=JSON.parse(localStorage.getItem("roleInfo"))
            if(roleInfo==null){
                return
            }
            state.list = roleInfo
        }
    }
}
/***    员工管理    ***/
const EmployeeHome = () => import(/* webpackChunkName: "employee" */ "@/views/employee/EmployeeHome")
const employeeDetail = () => import(/* webpackChunkName: "employee" */ "@/views/employee/EmployeeDetail")
const employeeEdit = () => import(/* webpackChunkName: "employee" */ "@/views/employee/EmployeeEdit")
const departure = () => import(/* webpackChunkName: "employee" */ "@/views/employee/Departure")

/***    员工录入    ***/
const employeeInput = () => import(/* webpackChunkName: "employeeInput" */ "@/views/employee/employeeInput/EmployeeInput")
const uploadIDCard = () => import(/* webpackChunkName: "employeeInput" */ "@/views/employee/employeeInput/UploadIDCard")
const basisInfo = () => import(/* webpackChunkName: "employeeInput" */ "@/views/employee/employeeInput/BasisInfo")
const bankInfo = () => import(/* webpackChunkName: "employeeInput" */ "@/views/employee/employeeInput/BankInfo")
const inaugurationInfo = () => import(/* webpackChunkName: "employeeInput" */ "@/views/employee/employeeInput/InaugurationInfo")

export default [
    {
        path: '/employeeHome',
        name: 'EmployeeHome',
        meta: {
            index: 3,
            title: "员工管理",
            useApp: true,
            needLogin: true
        },
        component: EmployeeHome,
    },
    {
        path: '/employeeDetail/:type/:employeeId?',
        name: 'employeeDetail',
        meta: {
            index: 5,
            title: "详情"
        },
        component: employeeDetail,
    },
    {
        path: '/employeeEdit',
        name: 'employeeEdit',
        meta: {
            index: 5,
            title: "编辑"
        },
        component: employeeEdit,
    },
    {
        path: '/departure/:employeeId/:projectId',
        name: 'departure',
        meta: {
            index: 6,
            title: "离职"
        },
        component: departure,
        props: true
    },


    {
        path: '/employeeInput',
        name: 'employeeInput',
        meta: {
            index: 5,
            title: "员工录入"
        },
        component: employeeInput,
        children: [{
            path: 'uploadIDCard',
            name: 'uploadIDCard',
            meta: {
                index: 6,
                title: "员工录入"
            },
            component: uploadIDCard,
        }, {
            path: 'basisInfo',
            name: 'basisInfo',
            meta: {
                index: 6,
            },
            component: basisInfo,
        }, {
            path: 'bankInfo',
            name: 'bankInfo',
            meta: {
                index: 6,
            },
            component: bankInfo,
        }, {
            path: 'inaugurationInfo',
            name: 'inaugurationInfo',
            meta: {
                index: 6,
            },
            component: inaugurationInfo,
        }]
    },
//
]
/***    审批    ***/
const approvalHome = () => import(/* webpackChunkName: "approval" */ "@/views/approval/ApprovalHome")
const homeNewApproval = () => import(/* webpackChunkName: "approval" */ "@/views/approval/HomeNewApproval")
const homeApprovalToMe = () => import(/* webpackChunkName: "approval" */ "@/views/approval/HomeApprovalToMe")
const homeApprovalFromMe = () => import(/* webpackChunkName: "approval" */ "@/views/approval/HomeApprovalFromMe")

/***    功能模块    ***/
const attendanceLeave = () => import(/* webpackChunkName: "approval" */ "@/views/approval/module/AttendanceLeave")

export default [
    {
        path: '/approvalHome',
        name: 'approvalHome',
        meta: {
            index: 3,
            title: "审批"
        },
        component: approvalHome,
        children: [
            {
                path: 'homeNewApproval',
                name: 'homeNewApproval',
                meta: {
                    index: 4,
                    title: "新审批"
                },
                component: homeNewApproval
            }, {
                path: 'homeApprovalToMe',
                name: 'homeApprovalToMe',
                meta: {
                    index: 4,
                    title: "审核"
                },
                component: homeApprovalToMe
            }, {
                path: 'homeApprovalFromMe',
                name: 'homeApprovalFromMe',
                meta: {
                    index: 4,
                    title: "我提交的"
                },
                component: homeApprovalFromMe
            },
        ]
    },
    {
        path: '/attendanceLeave',
        name: 'attendanceLeave',
        meta: {
            index: 5,
            title: "离职"
        },
        component: attendanceLeave
    },
]
import nw from "@/nvwa/versionLast/index";

const XGPush = {
    push (params,callback) {
        let url=""
        // let iosParams=params
        // iosParams.platform="ios"
        // nw.request({
        //     "method": 'POST',
        //     "url": url+'/v3/push/app',
        //     "data": iosParams,
        //     "header":{
        //         Authorization:'Basic YTdjNmUwOTgxYjAwYTpiNmU2Mjc0YTM2YzA4NjczMTkzYWFmYjBhYTUwM2I1Ng=='
        //     },
        //     success(response){
        //         callback(response)
        //     }
        // })
        return nw.request({
            "method": 'POST',
            "url": url+'/v3/push/app',
            "data": params,
            "header":{
                Authorization:'Basic NGQ4MWM5ODNkYTFjOTpkMzhkYjc3MjBkYjA3NzdkZTNjZTYzNDkzODkyMGRkNQ=='
            },
            success(response){
                callback(response)
            }
        })
    },
    pushAccount(params,callback){
        let url=""
        let iosParams={
            "audience_type": "account",
            "account_list": [
                "E18157180412"
            ],
            "environment": "dev",
            "platform": "ios",
            "message": {
                "title": "新闻",
                "content": "确认过眼神 是守岗绿晶人",
                "ios": {
                    "aps": {
                        "alert": {
                            "subtitle": "确认过眼神 是守岗绿晶人"
                        },
                        "badge_type": 0,
                        "category": "INVITE_CATEGORY"
                    },
                    "pageUrl": "https://www.lvjingclean.com/#/homeNewApproval"
                }
            },
            "message_type": "notify"
        }
    }

}

export default XGPush

import navigation from './modules/navigation'
import analytics from './modules/analytics'
import push from './modules/push'
import windowConfig from './modules/windowConfig'
import weChat from './modules/weChat'
import system from './modules/system'
//*********     直接使用web    *********
import request from '../web/modules/request'

export default {
    request,
    ...navigation,
    ...system,
    windowConfig,
    analytics,
    push,
    weChat
}
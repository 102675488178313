/***    已上线    ***/
import homeRoutes from './importRoutes/homeRoutes'
import employeeRoutes from './importRoutes/employeeRoutes'
import projectRoutes from './importRoutes/projectRoutes'
import approvalRoutes from './unusualRoutes/approvalRoutes'
import invoiceRoutes from './importRoutes/invoiceRoutes'
import suppliesRoutes from './importRoutes/suppliesRoutes'
import otherRoutes from './unusualRoutes/otherRoutes'
import registerRoutes from './importRoutes/registerRoutes'
import reportRoutes from './importRoutes/reportRoutes'
import questionnaireRoutes from './importRoutes/questionnaireRoutes'
import processRoutes from './importRoutes/processRoutes'

/***    待完善    ***/
import punchRoutes from './unusualRoutes/punchRoutes'
import newsRoutes from './unusualRoutes/newsRoutes'
import announceRoutes from './unusualRoutes/announceRoutes'
import learnCenterRoutes from './unusualRoutes/learnCenterRoutes'
import deviceRoutes from './unusualRoutes/deviceRoutes'

/***    限时开放    ***/
import womenDayRoutes from './tempRoutes/womenDayRoutes'

/***    测试    ***/
import testRoutes from './testRoutes'

export default [
    ...homeRoutes,
    ...employeeRoutes,
    ...projectRoutes,
    ...punchRoutes,
    ...processRoutes,
    ...newsRoutes,
    ...announceRoutes,
    ...approvalRoutes,
    ...learnCenterRoutes,
    ...invoiceRoutes,
    ...suppliesRoutes,
    ...reportRoutes,
    ...otherRoutes,
    ...deviceRoutes,
    ...testRoutes,
    ...registerRoutes,
    ...womenDayRoutes,
    ...questionnaireRoutes
]
export default {
    common:function(func,params){
        this.call(func,params)
    },
    call(func,params){
        if (window.NvwaJsBridge) {
            NvwaJsBridge.call(func,params)
        } else {
            // 如果没有注入则监听注入的事件
            window.addEventListener('NvwaJsBridgeReady', function () {
                window.NvwaJsBridge.call(func,params)
            });
        }
    }
}
import request from '@/api/http'

const project = {
    // post提交
    getProjectConfigList (params,callback) {
        let callbackInterceptor=data=>{
            if(data==='fail'){
                callback(data)
            }else{
                callback(data.map(item=>{
                    let projectManager=item.projectManager
                    if(item.projectRelList==null||item.projectRelList.length<1){
                        item.projectManager='暂无绑定主管'
                    }else{
                        item.projectManager=item.projectRelList[0].employeeName
                        for(let i=1;i<item.projectRelList.length;i++){
                            item.projectManager+='/'+item.projectRelList[i].employeeName
                        }
                    }
                    if(item.projectManager==='暂无绑定主管'&&projectManager!=null){
                        item.projectManager=projectManager
                    }
                    return item
                }))
            }
        }
        return request.post(process.env.BASE_URL+`/project/getProjectConfigList`, params,callbackInterceptor)
    },
    getProjectNews (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getProjectNews`, params,callback)
    },
    updateProjectConfig (params,callback) {
        return request.post(process.env.BASE_URL+`/project/updateProjectConfig`, params,callback)
    },
    updateProjectSite (params,callback) {
        params.method="PUT"
        return request.post(process.env.BASE_URL+`/project/updateProjectSite`, params,callback)
    },
    addProjectApply (params,callback) {
        return request.post(process.env.BASE_URL+`/project/addProjectApply`, params,callback)
    },
    getApplyProjectList (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getApplyProjectList`, params,callback)
    },
    getAuditProjectList (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getAuditProjectList`, params,callback)
    },
    projectAudit (params,callback) {
        return request.post(process.env.BASE_URL+`/project/projectAudit`, params,callback)
    },
    projectSignOutApply (params,callback) {
        return request.post(process.env.BASE_URL+`/project/projectSignOutApply`, params,callback)
    },
    addServiceMargin (params,callback) {
        return request.post(process.env.BASE_URL+`/project/addServiceMargin`, params,callback)
    },
    getWorkProcessList (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getWorkProcessList`, params,callback)
    },
    getWaitProcessList (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getWaitProcessList`, params,callback)
    },
    processDetail (params,callback) {
        return request.post(process.env.BASE_URL+`/project/processDetail`, params,callback)
    },
    getProjectBaseNews (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getProjectBaseNews`, params,callback)
    },
    getLastProjectContractNews (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getLastProjectContractNews`, params,callback)
    },
}

export default project

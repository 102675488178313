var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-overlay",
    {
      attrs: { show: _vm.show },
      on: {
        click: function($event) {
          return _vm.$emit("showChange", false)
        }
      }
    },
    [
      _c("transition", { attrs: { name: _vm.transitionName } }, [
        _c("div", { ref: "contentRef", staticClass: "popover_container" }, [
          _c("div", {
            staticClass: "popover_triangle",
            style: {
              top: _vm.triangle.top + "px",
              left: _vm.triangle.left + "px",
              borderWidth: _vm.triangleWidth / 2 + "px",
              borderColor: _vm.triangle.class
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "popover_content",
              style: {
                top: _vm.content.top + "px",
                left: _vm.content.left + "px",
                width: _vm.contentWidth + "px"
              }
            },
            [_vm._t("default")],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/***    新闻    ***/
const newsList = () => import(/* webpackChunkName: "news" */ "@/views/news/NewsList")

export default [
    {
        path: '/newsList/:type',
        name: 'newsList',
        meta: {
            index: 3,
            title: "新闻"
        },
        component: newsList,
        props: true
    },
]
/***    register    ***/
const RegisterByPhone = () => import(/* webpackChunkName: "register" */ "@/views/register/RegisterByPhone")


export default [
    {
        path: '/register/byPhone',
        name: 'RegisterByPhone',
        meta: {
            index: 100,
            title: "手机注册"
        },
        component: RegisterByPhone,
    },
]
/**
 * api接口的统一出口
 */

// employeeClock构建模块接口
import employeeClock from '@/api/modules/employeeClock'
import user from '@/api/modules/user'
import materials from "./modules/materials";
import menu from "./modules/menu";
import employee from "./modules/employee";
import organizationRelationship from "./modules/organizationRelationship";
import project from "./modules/project";
import process from "./modules/process";
import weeklyReports from "./modules/weeklyReports";
import XGPush from "./modules/XGPush";
import invoice from "./modules/invoice";
import materialsSupplier from "./modules/materialsSupplier";
import role from "./modules/role";
import materialsAudit from "./modules/materialsAudit";
import questionnaire from "./modules/questionnaire";
import clock from "./modules/clock";
import equipment from './modules/equipment'

// 导出接口
export default {
    employeeClock,
    user,
    menu,
    employee,
    organizationRelationship,
    project,
    process,
    materials,
    weeklyReports,
    XGPush,
    invoice,
    materialsSupplier,
    role,
    materialsAudit,
    questionnaire,
    clock,
    equipment
}
